@media screen and (max-width: 500px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
#logo{
  max-width: 180px;
  /* border: 1px solid #608dfd; */
  margin-top: -34px;
}
.carousel-inner .item {
  /*   max-height: 680px; */
    height: 100vh;
    background: url('https://cdn.pixabay.com/photo/2015/06/24/15/45/hands-820272_960_720.jpg') no-repeat center top;
    background-size: cover;
  }
  
  .carousel-inner .item:nth-child(2) {
    background-image: url('https://images7.alphacoders.com/720/thumb-1920-720075.jpg');
  }
  
  .carousel-inner .item:nth-child(3) {
    background-image: url('https://www.teahub.io/photos/full/84-842800_hd-wallpaper-software.jpg');
  }
  
  .carousel-inner .item:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .carousel-control {
    width: 30%;
  }
  
  .carousel-control .fa {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
  }
  
  .carousel-control .fa.fa-chevron-right {
    right: 50%;
    margin-right: -10px;
  }
  
  @media screen and (min-width: 768px) {
    .carousel-control .fa {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .carousel-control .fa-chevron-left {
      margin-left: -15px;
    }
    img {
      display: block;
      height: auto;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      object-fit: contain;
      position: relative;
      top: 50%;
      transform: translate(-50%, 0%);
      width: auto;
    }
  }
  
  
  /**
   * Slider caption
   */
  
  .right.carousel-control,
  .left.carousel-control {
    display: none;
  }
  
  .carousel-caption {
    bottom: 36%;
  }
  
  .carousel-caption h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .carousel-caption p {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 50px;
  }
  
  @media screen and (min-width: 768px) {
    .carousel-caption {
      right: 20%;
      left: 20%;
    }
  }
  
  .btn-transparent {
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
  }
  
  .btn-transparent:hover {
    background-color: #fff;
  }
  
  .btn-rounded {
    border-radius: 70px;
  }
  
  .btn-large {
    padding: 11px 45px;
    font-size: 18px;
  }
  
  
  /**
   * Change animation duration
   */
  
  .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
  
  .blogs img {
    width: 100%;
  }
